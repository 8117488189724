import styled, { css } from 'styled-components';
import palette from '@/ui/css/palette';

const TitleStyle = css`
  color: ${palette.dark};
  font-weight: bold;
  margin: 0;
  padding: 0;
`;
export default TitleStyle;


export const FormTitle = styled.h2`
  ${TitleStyle};
  
  margin-bottom: 20px;
  font-size: 32px;
`;

export const PageTitle = styled.p`
  margin-bottom: 50px;
  font-size: 28px;
  font-weight: bold;
  color: ${palette.dark};
`;
