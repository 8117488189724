import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import PasswordAPI from '@/api/password';
import { emailFormatError } from '@/util/validation';
import PasswordFindForm from '@/components/organisms/PasswordFindForm';
import FlexContainer from '@/components/atoms/Div';


function PasswordFind() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (email) => {
    setErrorMessage('');

    const emailErrorCheck = emailFormatError(email);
    if (emailErrorCheck !== '') {
      setErrorMessage(emailErrorCheck);
      return ;
    }

    setIsLoading(true);
    try {
      const response = await PasswordAPI.sendEmail(email);
      if (response.status === 200) {
        toast('이메일이 전송되었습니다.');
      }
    } catch {
      setErrorMessage('없는 이메일입니다. 다시 한번 확인해 주세요.');
    }
    setIsLoading(false);
  };

  return (
    <StyledFlexContainer>
     <PasswordFindForm errorMessage={errorMessage} onSubmit={submit} isLoading={isLoading}/>
    </StyledFlexContainer>
  );
}

export default PasswordFind;


const StyledFlexContainer = styled(FlexContainer)`
  height: 70vh;
`;
