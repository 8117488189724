export const emailFormatError = (email) => {
  if (email === '') {
    return '이메일은 필수로 입력되어야 합니다.';
  }
  const emailFormatRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  if (!emailFormatRegex.test(email)) {
    return '올바른 이메일 형식이 아닙니다.';
  }
  return '';
};

export const passwordRequiredError = (password) => (password === '' ? '비밀번호는 필수로 입력되어야 합니다.' : '');

export const passwordFormatError = (password) => {
  const passwordFormatRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+~[\]{}\\|;:'"`,.<>/?])[A-Za-z\d!@#$%^&*()\-_=+~[\]{}|;:'"`,.<>\\/?]{8,20}$/g;
  return !passwordFormatRegex.test(password) ? '영문+숫자+특수문자 조합으로 8~20자 비밀번호를 만들어주세요.' : '';
};

export const passwordCompareError = (password, passwordConfirm) => (password !== passwordConfirm ? '비밀번호가 서로 맞지 않습니다.' : '');

export const nameFormatError = (name) => {
  const trimmedName = name.trim();
  const nameLength = trimmedName.length;

  return !(nameLength >= 1 && nameLength <= 12) ? '1자 이상 12자 이내의 이름을 입력해 주세요.' : '';
};

export const phoneNumberFormatError = (phoneNumber) => {
  if (phoneNumber.first === '' && phoneNumber.second === '' && phoneNumber.third === '') {
    return '';
  }

  const fullPhoneNumber = `${phoneNumber.first}-${phoneNumber.second}-${phoneNumber.third}`;
  const phoneNumberRegex = /^[0-9]{3}[-]+[0-9]{4}[-]+[0-9]{4}$/;

  return !phoneNumberRegex.test(fullPhoneNumber) ? '올바른 전화 번호 형식이 아닙니다.' : '';
};
