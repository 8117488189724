import React from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { upperFirst } from 'lodash';

import routes from '@/configs/routes';
import palette from '@/ui/css/palette';
import UserAPI from '@/api/users';
import { nameFormatError, passwordFormatError } from '@/util/validation';

import { PageTitle } from '@/components/atoms/Title';
import { PrimaryButton } from '@/components/atoms/Button';
import ServiceUse from '@/components/molecules/ServiceUse';
import StaticBar from '@/components/molecules/StaticBar';
import PasswordChangeManager from '@/components/organisms/PasswordChangeManager';
import InputBox from '@/components/molecules/InputBox';

class UserEdit extends React.Component {
  constructor(props) {
    super(props);

    this.defaultPassword = '비밀번호1234!';

    this.state = {
      scopeList: [],
      authority: '',
      email: '',
      originalName: '',
      name: '',
      password: this.defaultPassword,
      clickSave: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo() {
    try {
      const userInfo = this.props.location.state.passState;
      this.setState({
        user: userInfo,
        scopeList: userInfo.scope,
        authority: userInfo.role,
        email: userInfo.user_id,
        name: userInfo.username,
        originalName: userInfo.username,
      });
    } catch {
      // url 을 직접 입력해서 들어올 경우 redirect
      this.backToUserManager();
    }
  }

  backToUserManager() {
    this.props.history.push(routes.USER_MANAGER);
  }

  handleChange(e, key) {
    this.setState({ [key]: e.target.value });
  }

  handleCheck(e, key) {
    this.setState({ [key]: e.target.checked });
  }

  passwordHasUpdated() {
    return this.state.password !== this.defaultPassword;
  }

  nameHasUpdated() {
    const { name, originalName } = this.state;
    return name !== originalName;
  }

  noChange() {
    return !this.passwordHasUpdated() && !this.nameHasUpdated();
  }

  async save() {
    this.setState({ clickSave: true, isLoading: true });

    const { name, password, user } = this.state;
    const passwordHasUpdated = this.passwordHasUpdated();

    if ((this.nameHasUpdated() || passwordHasUpdated) && !nameFormatError(name)
        && (passwordHasUpdated ? !passwordFormatError(password) : true)) {
      try {
        const response = await UserAPI.updateUser(user.id, name, passwordHasUpdated ? password : undefined);
        if (response.status === 200) toast('이름과 비밀번호가 저장되었습니다.');
      } catch {
        console.log('error on PUT api');
        return;
      }
    }

    this.setState({
      originalName: name,
      isLoading: false,
    });
  }

  render() {
    const {
      scopeList, authority, email, name, password, clickSave, isLoading,
    } = this.state;

    return (
      <div className="UserEdit">
        <StyledDiv>
          <PageTitle>사용자 편집</PageTitle>
          <StyledPrimaryButton
            className="MarginButton"
            onClick={() => this.backToUserManager()}
          >
            목록으로
          </StyledPrimaryButton>
        </StyledDiv>

        <StyledStaticBar leftText="서비스 이용">
          <ServiceUse scopeList={scopeList} />
        </StyledStaticBar>

        <StyledStaticBar leftText="권 한">
          <DarkLightSpan>{upperFirst(authority)}</DarkLightSpan>
        </StyledStaticBar>

        <StyledStaticBar leftText="E-mail">
          <DarkLightSpan>{email}</DarkLightSpan>
        </StyledStaticBar>

        <StyledStaticBar leftText="이 름">
          <InputBox
            value={name}
            error={clickSave && nameFormatError(name)}
            errorMessage={nameFormatError(name)}
            placeholder="이름을 입력해 주세요"
            onEmitChange={(e) => this.handleChange(e, 'name')}
          />
        </StyledStaticBar>

        <StyledStaticBar leftText="비밀번호 변경">
          <PasswordChangeManager
            value={password}
            error={clickSave && passwordFormatError(password)}
            onEmitChange={(e) => this.handleChange(e, 'password')}
          />
        </StyledStaticBar>

        <StyledPrimaryButton onClick={() => this.save()} disabled={isLoading || this.noChange()}>저 장</StyledPrimaryButton>
      </div>
    );
  }
}

export default UserEdit;

const StyledStaticBar = styled(StaticBar)`
  margin-bottom: 20px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  float: right;
  width: 120px;
  height: 40px;
  background-color: ${palette.pureWhite};
  color: ${palette.darkLight};
  border: 1px solid ${palette.borderGray};
  
  &.MarginButton {
    margin-bottom: 50px;
  }
  
  ${(props) => props.disabled && css`
    color: ${palette.grayDark};
    background-color: ${palette.whiteDark};
  `};
`;

const DarkLightSpan = styled.span`
  color: ${palette.darkLight};
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
