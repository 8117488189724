import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import palette from '@/ui/css/palette';
import zIndex from '@/ui/css/zIndex';
import Token from '@/util/token';
import { UserContext } from '@/util/context';

import { OrbitronSpan } from '@/components/atoms/Label';
import * as CONFIG from '@/config';

class DropDownUserContent extends React.Component {
  newTab(url) {
    window.open(url, '_blank');
  }

  logOut() {
    const value = this.context;
    value.setToken({});
    toast('로그아웃 되었습니다.');
  }

  getUserId() {
    const value = this.context;
    const decodedAccessToken = Token.getDecodedAccessToken(value.accessToken);
    return decodedAccessToken ? decodedAccessToken.identity : '';
  }

  render() {
    return (
      <StyledDropDownContent className="DropDownUserContent">
        <StyledDropDownHeader className="DropDownUserHeader">
          <span className="Header__setting">설정</span>
          <span className="Header__id">{this.getUserId()}</span>
        </StyledDropDownHeader>
        <div className="DropDownUserBody">
          <StyledDropDownItem onClick={() => this.newTab(CONFIG.LOPLAT_I_URL)}>
            <StyledOrbitronSpan>loplat Analytics</StyledOrbitronSpan>
            로 이동
          </StyledDropDownItem>
          <StyledDropDownItem onClick={() => this.newTab(loplatIManualLink)}>
            <StyledOrbitronSpan>loplat i</StyledOrbitronSpan>
            메뉴얼
          </StyledDropDownItem>
          <StyledDropDownItem onClick={() => this.logOut()}>
            로그아웃
          </StyledDropDownItem>
        </div>
      </StyledDropDownContent>
    );
  }
}
export default DropDownUserContent;
DropDownUserContent.contextType = UserContext;

const loplatIManualLink = 'https://www.notion.so/loplat-i-2551a289204244c3b958a5ece56f26de';

const StyledDropDownContent = styled.div`
  position: absolute;
  z-index: ${zIndex.dropDownContent};
  margin-left: -210px;
  width: 300px;
  
  background-color: ${palette.pureWhite};
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const StyledDropDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  height: 32px;
  padding: 0 20px;
  font-size: 12px;
  border: 1px solid ${palette.whiteDark};
  
  .Header {
    &__setting {
      color: ${palette.inactiveStatus};
    }
    
    &__id {
      color: ${palette.primaryLight};
    }
  }
`;

const StyledDropDownItem = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  padding: 0 20px;
  
  background-color: ${palette.pureWhite};
  color: ${palette.dark};
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  
  &:hover {
    background-color: ${palette.whiteDark};
  }
`;

const StyledOrbitronSpan = styled(OrbitronSpan)`
  color: ${palette.dark};
  padding-right: 3px;
`;
