import React from 'react';
import styled from 'styled-components';
import DropDownUser from '@/components/organisms/Header/DropDownUser';


function DropDowns() {
  return (
    <FlexContainer className="HeaderDropDowns">
      <DropDownUser/>
    </FlexContainer>
  );
}
export default DropDowns;


const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
