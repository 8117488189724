import React from 'react';
import styled from 'styled-components';
import palette from '@/ui/css/palette';
import { FormControlLabel, Checkbox as MUCheckbox } from '@material-ui/core';


function EmailMemorizeCheckbox({ checked, handleChange }) {
  const onChange = e => {
    handleChange(e.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          color="primary"
          size="small"
        />
      }
      label={<Label>이메일 기억하기</Label>}
    />
  );
}

export default EmailMemorizeCheckbox;


const Checkbox = styled(MUCheckbox)`
  color: ${palette.primaryLight} !important;
`;

const Label = styled.p`
  margin: 0;
  font-size: 14px;
`;