import styled from 'styled-components';
import palette from '@/ui/css/palette';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default FlexContainer;


export const ShadowCard = styled.div`
  background-color: ${palette.pureWhite};
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;
