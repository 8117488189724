import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UserContext } from '@/util/context';
import Token from '@/util/token';
import AppRouter from '@/AppRouter';


class App extends React.Component {
  constructor(props) {
    super(props);

    Token.initializeToken();

    this.setToken = (token) => {
      this.setState({accessToken: token.access_token});
      Token.setToken(token);
    };

    this.state = {
      accessToken: Token.getAccessToken(),
      setToken: this.setToken,
    };
  }

  render() {
    return (
      <div className="App">
        <UserContext.Provider value={this.state}>
          <AppRouter/>
        </UserContext.Provider>
        <ToastContainer />
      </div>
    );
  }
}

export default App;
