/** My Profile 페이지와 login 관련 * */
import ManagerAPI from './index';

class MyInfoAPI {
  wakeUp() {
    return ManagerAPI.options('/warmup');
  }

  auth({
    grantType, email, password, refreshToken,
  }) {
    return ManagerAPI.post('/oauth/token', {
      grant_type: grantType,
      // NOTE: 백엔드 필드명이 혼잡함
      username: email,
      password,
      refresh_token: refreshToken,
    });
  }

  login(email, password) {
    return this.auth({
      grantType: 'password',
      email,
      password,
    });
  }

  refresh(refreshToken) {
    return this.auth({
      grantType: 'refresh_token',
      refreshToken,
    });
  }

  userInfo() {
    return ManagerAPI.get('/users/me');
  }

  updateInfo(username, password) {
    return ManagerAPI.patch('/users/me', { username, password });
  }
}
export default new MyInfoAPI();
