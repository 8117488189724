import styled from 'styled-components';
import palette from '@/ui/css/palette';


export const NormalLabel = styled.p`
  font-size: 14px;
`;
export default NormalLabel;


export const FormErrorLabel = styled.p`
  color: ${palette.red};
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 13px;
  height: 13px;
`;

export const OrbitronSpan = styled.span`
  font-family: Orbitron;
  font-weight: bold;
`;


