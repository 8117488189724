import styled from 'styled-components';
import palette from '@/ui/css/palette';
import { Button as ReactstrapButton } from 'reactstrap';

const Button = styled.button`
  background: none;
  border: none;
  
  &:focus {
    outline: none;
  }
`;
export default Button;


export const PrimaryButton = styled(Button)`
  background-color: ${palette.primaryLight};
  border-radius: 5px;
  color: ${palette.pureWhite};
`;

export const BButton = styled(ReactstrapButton)`
  font-size: 14px;
  line-height: 16px;
  padding: 8px 26px;
  
  &.btn-primary {
    background-color: ${palette.primaryLight};
    border-color: ${palette.primaryLight};
  } 
  
  &.btn-outline-secondary {
    border-color: ${palette.whiteDarkDark};
    color: ${palette.darkLight};
  }
  
  &.btn-lg {
    height: 56px;
    font-size: 18px;
  }
`;

export const DropDownButton = styled(Button)`
  background-color: ${palette.pureWhite};

  &:hover {
    background-color: ${palette.buttonGray};
  }
`;
