/** User Manager 페이지 관련 * */
import ManagerAPI from '@/api/index';

class UserAPI {
  userList(page = 1, perPage, query) {
    return ManagerAPI.get('/users', { params: { page, per_page: perPage, query } });
  }

  updateUser(uid, username, password) {
    return ManagerAPI.patch(`/users/${uid}`, {
      username, password,
    });
  }
}
export default new UserAPI();
