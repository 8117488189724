import React from 'react';
import styled from 'styled-components';
import useInput from '@/util/hook';
import { enterKeyPressed } from '@/util/event';

import { FormTitle } from '@/components/atoms/Title';
import { FormInput } from '@/components/atoms/Input';
import { FormErrorLabel } from '@/components/atoms/Label';
import { BButton } from '@/components/atoms/Button';


function PasswordResetForm(props) {
  const { errorMessage, onSubmit, isLoading } = props;
  const [newPassword, setNewPassword] = useInput('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useInput('');

  return (
    <StyledResetForm>
      <FormTitle>비밀번호 재설정</FormTitle>
      <FormInput value={newPassword} onChange={setNewPassword} placeholder="신규 비밀번호" type="password" error={errorMessage}/>
      <FormInput value={newPasswordConfirm} onChange={setNewPasswordConfirm} placeholder="신규 비밀번호 확인" type="password"
                 error={errorMessage} onKeyPress={(e) => enterKeyPressed(e) && onSubmit(newPassword, newPasswordConfirm)}
      />
      <FormErrorLabel>{errorMessage}</FormErrorLabel>
      <BButton onClick={() => onSubmit(newPassword, newPasswordConfirm)} color="primary" size="lg" disabled={isLoading}>비밀번호 재설정</BButton>
    </StyledResetForm>
  );
}
export default PasswordResetForm;


const StyledResetForm = styled.div`
  display: flex;
  flex-flow: nowrap column;
  width: 350px;
`;
