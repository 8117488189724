import React, { useEffect } from 'react';
import styled from 'styled-components';
import palette from '@/ui/css/palette';
import Header from '@/components/organisms/Header';
import ExpireCheck from '@/util/token/expireCheck';


function Base(props) {
  const { children } = props;

  useEffect(() => {
    ExpireCheck.startNewSetInterval();
    return(() => {
      ExpireCheck.clearSetInterval();
    })
  });

  return (
    <div className="Base">
      <FixedHeader>
        <Header/>
      </FixedHeader>
      <BodyDiv className="Body">
        <ContentDiv>{children}</ContentDiv>
      </BodyDiv>
    </div>
  );
}
export default Base;


const FixedHeader = styled.div`
  position: fixed;
  width: 100%;
`;

const BodyDiv = styled.div`
  padding-top: 80px;

  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  background-color: ${palette.backgroundGray};
`;

const ContentDiv = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 1200px;
`;
