import React, { useContext } from 'react';
import { Nav, NavItem } from 'reactstrap';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';

import routes from '@/configs/routes';
import { UserContext } from '@/util/context';
import Token from '@/util/token';
import palette from '@/ui/css/palette';

import LinkButton from '@/components/atoms/LinkButton';


function Navigation() {
  const value = useContext(UserContext);
  const location = useLocation();

  const boldTextRoute = (route) => {
    return location.pathname.includes(route);
  };

  const ProtectedNavItem = (props) => {
    const { title, path } = props;

    const decodedAccessToken = Token.getDecodedAccessToken(value.accessToken);
    const userRole = decodedAccessToken ? decodedAccessToken.user_claims.role : '';

    return (
      userRole === 'manager' &&
        <NavItem>
          <StyledLinkButton title={title} path={path} bold={boldTextRoute(path)} />
        </NavItem>
    );
  };

  return (
    <FlexContainer className={"Navigation"}>
      <Nav>
        <NavItem>
          <StyledLinkButton title={"My Profile"} path={routes.MY_PROFILE} bold={boldTextRoute(routes.MY_PROFILE)}/>
        </NavItem>
        <ProtectedNavItem title={"User Manager"} path={routes.USER_MANAGER} />
      </Nav>
    </FlexContainer>
  );
}
export default Navigation;


const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLinkButton = styled(LinkButton)`
  margin: 0 10px;
  font-size: 16px;
  ${props => props.bold && css`
    font-weight: bold;
    text-shadow: 1px 0 ${palette.darkLight};
    letter-spacing:1px;
  `};
`;
