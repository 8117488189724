import React, { useState } from 'react';
import { range, difference, sample, shuffle } from 'lodash';
import styled from 'styled-components';
import palette from '@/ui/css/palette';
import { passwordFormatError } from '@/util/validation';
import { BInput } from '@/components/atoms/Input';
import { PrimaryButton } from '@/components/atoms/Button';


function PasswordChangeManager(props) {
  const { onEmitChange, value, error, className } = props;
  const [clickButton, setClickButton] = useState(false);

  const createRandomPassword = () => {
    const randomList = [];
    const allCharRange = range(33, 127);
    const numberRange = range(48, 58);
    const alphabetRange = range(65, 91).concat(range(97, 123));
    const specialCharRange = difference(allCharRange, numberRange.concat(alphabetRange));

    // 필수 3요소 추가 (영어, 숫자, 특수문자)
    randomList.push(String.fromCharCode(sample(numberRange)));
    randomList.push(String.fromCharCode(sample(alphabetRange)));
    randomList.push(String.fromCharCode(sample(specialCharRange)));
    // 랜덤 5~17자 추가 (8~20자)
    for (let i=0; i < sample(range(5, 18)); i++) {
      randomList.push(String.fromCharCode(sample(allCharRange)));
    }

    const randomPassword = shuffle(randomList).reduce((a, b) => a + b);

    onEmitChange({target: {value: randomPassword}}, 'password');
    setClickButton(true);
  };

  const showError = clickButton && error;

  return (
    <div className={className}>
      {showError && <RedSpan>{passwordFormatError(value)}</RedSpan>}
      <StyledPrimaryButton onClick={() => createRandomPassword()}>비밀번호 초기화</StyledPrimaryButton>
      <StyledBInput
        disabled={!clickButton}
        type={clickButton ? "" : "password"}
        value={value}
        error={showError ? 1 : 0}
        placeholder={"새로운 비밀번호 입력"}
        onChange={(e) => onEmitChange(e, 'password')}
      />
    </div>
  );
}

export default PasswordChangeManager;


const StyledBInput = styled(BInput)`
  float: right;
  width: 360px;
  height: 40px;
  border-color: ${props => props.error ? palette.red : palette.whiteDarkDark};
`;

const RedSpan = styled.span`
  color: ${palette.red};
  margin-right: 20px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 130px;
  height: 40px;
  font-size: 12px;
  margin-right: 10px;
  background-color: ${palette.pureWhite};
  color: ${palette.black};
  border: 1px solid ${palette.borderGray};
`;
