import React from 'react';
import styled from 'styled-components';
import palette from '@/ui/css/palette';

function StaticBar({
  children, leftText, description, className,
}) {
  return (
    <FlexContainer className={className}>
      <TitleContainer>
        <DarkSpan>{leftText}</DarkSpan>
        <GraySpan>{description}</GraySpan>
      </TitleContainer>
      <div>{children}</div>
    </FlexContainer>
  );
}
export default StaticBar;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${palette.pureWhite};
  height: 80px;
  padding: 30px;
  border: 1px solid ${palette.borderGray};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DarkSpan = styled.span`
  color: ${palette.black};
`;

const GraySpan = styled.span`
  color: ${palette.darkLight};
  font-size: 12px;
`;
