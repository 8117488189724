/**
 * deprecated.
 * 비밀번호 찾기, 비밀번호 재설정 관련
 * */
import ManagerAPI from '@/api/index';

class PasswordAPI {
  sendEmail(email) {
    return ManagerAPI.post('/auth/users/password/token', { email });
  }

  resetPassword(pw, token) {
    return ManagerAPI.put('/auth/users/password', { pw, token });
  }

  testToken(token) {
    return ManagerAPI.get(`/auth/users/password/token/${token}`);
  }
}
export default new PasswordAPI();
