import React from 'react';
import styled from 'styled-components';
import { UncontrolledPopover } from 'reactstrap';
import { DropDownButton } from '@/components/atoms/Button';
import DropDownUserContent from '@/components/organisms/Header/DropDownUser/DropDownUserContent';


function DropDownUser() {
  return (
    <div className="DropDownUser">
      <StyledDropDownButton id="PopoverLegacy" type="button">
        <img src={require(`@/ui/image/buttonIcon/user.svg`)} alt="" style={{ pointerEvents: 'none' }}/>
      </StyledDropDownButton>

      <UncontrolledPopover trigger="legacy" placement="bottom-start" target="PopoverLegacy" hideArrow>
        <DropDownUserContent />
      </UncontrolledPopover>
    </div>
  );
}
export default DropDownUser;


const StyledDropDownButton = styled(DropDownButton)`
  width: 96px;
  height: 78px;
`;
