import React from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { upperFirst } from 'lodash';

import palette from '@/ui/css/palette';
import MyInfoAPI from '@/api/myInfo';
import Token from '@/util/token';
import { UserContext } from '@/util/context';
import {
  nameFormatError, passwordCompareError, passwordFormatError, phoneNumberFormatError,
} from '@/util/validation';

import { PageTitle } from '@/components/atoms/Title';
import { PrimaryButton } from '@/components/atoms/Button';
import StaticBar from '@/components/molecules/StaticBar';
import PasswordChangeInput from '@/components/organisms/PasswordChangeInput';
import ServiceUse from '@/components/molecules/ServiceUse';
import InputBox from '@/components/molecules/InputBox';
import PhoneNumberInputBox from '@/components/molecules/PhoneNumberInputBox';
import { getFirestoreUserInfo, setFirestorePhoneNumber } from '@/util/function/firestore';

class MyProfile extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      scopeList: [],
      authority: '',
      email: '',
      originalName: '',
      name: '',
      originalPhoneNumber: { first: '', second: '', third: '' },
      phoneNumber: { first: '', second: '', third: '' },
      password: { current: '', new: '', newConfirm: '' },
      passwordError: {},
      clickSave: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUserInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserInfo() {
    (async () => {
      let response;
      try {
        response = await MyInfoAPI.userInfo();
      } catch (e) {
        console.log('error on GET api');
        return;
      }

      const { data } = response;
      this._isMounted && this.setState({
        authority: data.role,
        email: data.user_id,
        name: data.username,
        originalName: data.username,
      });

      const firestoreUserInfo = await getFirestoreUserInfo(data.user_id);
      const phoneNumber = firestoreUserInfo.phone.pop();
      this._isMounted && this.setState({
        phoneNumber: phoneNumber ? this.splitPhoneNumber(phoneNumber) : this.splitPhoneNumber('--'),
        originalPhoneNumber: phoneNumber ? this.splitPhoneNumber(phoneNumber) : this.splitPhoneNumber('--'),
      });
    })();

    const value = this.context;
    const decodedAccessToken = Token.getDecodedAccessToken(value.accessToken);
    const scopeList = decodedAccessToken ? decodedAccessToken.scope.split(' ') : [];
    this.setState({ scopeList });
  }

  splitPhoneNumber(fullPhoneNumber) {
    const splitPhoneNumber = fullPhoneNumber.split('-');
    return {
      first: splitPhoneNumber[0],
      second: splitPhoneNumber[1],
      third: splitPhoneNumber[2],
    };
  }

  handleChange(e, key) {
    this.setState({ [key]: e.target.value });
  }

  handleObjectChange(e, target, key) {
    this.setState({ [target]: { ...this.state[target], [key]: e.target.value } });
  }

  nameHasUpdated() {
    const { name, originalName } = this.state;
    return name !== originalName;
  }

  passwordHasUpdated() {
    const { password } = this.state;
    return !(password.new === '' && password.newConfirm === '');
  }

  phoneNumberHasUpdated() {
    const { phoneNumber, originalPhoneNumber } = this.state;
    return !(phoneNumber.first === originalPhoneNumber.first
      && phoneNumber.second === originalPhoneNumber.second
      && phoneNumber.third === originalPhoneNumber.third);
  }

  noChange() {
    return !(this.passwordHasUpdated() || this.nameHasUpdated() || this.phoneNumberHasUpdated());
  }

  async checkPasswordValidity() {
    let currentError = false; let formatError; let
      compareError;
    const { password } = this.state;
    // login API로 현재 비밀번호 체크
    try {
      await MyInfoAPI.login(this.state.email, this.state.password.current);
    } catch (e) {
      currentError = '입력하신 비밀번호를 다시 확인해 주세요.';
    }
    // 비밀번호 format 체크
    formatError = passwordFormatError(password.new);
    // 비밀번호 비교 체크
    compareError = passwordCompareError(password.new, password.newConfirm);

    this.setState({ passwordError: { currentError, formatError, compareError } });

    return !(currentError || formatError || compareError);
  }

  async save() {
    this.setState({ clickSave: true, isLoading: true, passwordError: {} });

    const {
      name, password,
    } = this.state;
    const passwordHasUpdated = this.passwordHasUpdated();
    const isValidPassword = passwordHasUpdated ? await this.checkPasswordValidity() : true;

    const phoneNumberHasUpdated = this.phoneNumberHasUpdated();
    if (phoneNumberHasUpdated && !nameFormatError(name) && isValidPassword) {
      this.savePhoneNumber().then(() => toast('전화 번호가 저장되었습니다.')).catch(() => {});
    }

    if ((this.nameHasUpdated() || passwordHasUpdated) && !nameFormatError(name) && isValidPassword) {
      try {
        const response = await MyInfoAPI.updateInfo(name, passwordHasUpdated ? password.new : undefined);
        if (response.status === 200) toast('이름과 비밀번호가 저장되었습니다.');
      } catch {
        console.log('error on PUT api');
        return;
      }
    }

    this._isMounted && this.setState({
      isLoading: false,
      originalName: name,
    });
  }

  async savePhoneNumber() {
    const { phoneNumber, email } = this.state;

    return new Promise((resolve) => {
      if (!phoneNumberFormatError(phoneNumber)) {
        const fullPhoneNumber = phoneNumber.first === '' ? null : `${phoneNumber.first}-${phoneNumber.second}-${phoneNumber.third}`;
        setFirestorePhoneNumber(
          email,
          fullPhoneNumber,
        );

        this._isMounted && this.setState({
          originalPhoneNumber: phoneNumber,
        });
        resolve();
      } else {
        throw Error('InvalidPhoneNumber');
      }
    });
  }

  render() {
    const {
      scopeList, authority, email, name, phoneNumber, clickSave, password, passwordError, isLoading,
    } = this.state;

    return (
      <div className="MyProfile">
        <PageTitle>내 프로필 관리</PageTitle>

        <StyledStaticBar leftText="서비스 이용">
          <ServiceUse scopeList={scopeList} />
        </StyledStaticBar>

        <StyledStaticBar leftText="권 한">
          <DarkLightSpan>{upperFirst(authority)}</DarkLightSpan>
        </StyledStaticBar>

        <StyledStaticBar leftText="E-mail">
          <DarkLightSpan>{email}</DarkLightSpan>
        </StyledStaticBar>

        <StyledStaticBar leftText="이 름">
          <InputBox
            value={name}
            error={clickSave && nameFormatError(name)}
            errorMessage={nameFormatError(name)}
            placeholder="이름을 입력해 주세요"
            onEmitChange={(e) => this.handleChange(e, 'name')}
          />
        </StyledStaticBar>

        <StyledStaticBar leftText="휴대 전화 번호">
          <PhoneNumberInputBox
            value={phoneNumber}
            error={clickSave && this.phoneNumberHasUpdated() && phoneNumberFormatError(phoneNumber)}
            errorMessage={this.phoneNumberHasUpdated() && phoneNumberFormatError(phoneNumber)}
            onEmitChange={(e, key) => this.handleObjectChange(e, 'phoneNumber', key)}
          />
        </StyledStaticBar>

        <PasswordChangeInput
          values={password}
          errors={clickSave ? passwordError : {}}
          onEmitChange={(e, key) => this.handleObjectChange(e, 'password', key)}
        />

        <StyledPrimaryButton onClick={() => this.save()} disabled={isLoading || this.noChange()}>
          저 장
        </StyledPrimaryButton>
      </div>
    );
  }
}
export default MyProfile;
MyProfile.contextType = UserContext;

const StyledStaticBar = styled(StaticBar)`
  margin-bottom: 20px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  float: right;
  margin-top: 20px;
  width: 120px;
  height: 40px;
  background-color: ${palette.pureWhite};
  color: ${palette.darkLight};
  border: 1px solid ${palette.borderGray};
  
  ${(props) => props.disabled && css`
    color: ${palette.grayDark};
    background-color: ${palette.whiteDark};
  `};
`;

const DarkLightSpan = styled.span`
  color: ${palette.darkLight};
`;
