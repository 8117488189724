import React from 'react';
import styled from 'styled-components';
import palette from '@/ui/css/palette';
import Header from '@/components/organisms/Header';


function AuthBase(props) {
  const { children } = props;

  return (
    <div className="AuthBase">
      <FixedHeader>
        <Header/>
      </FixedHeader>
      <BodyDiv className="Body">
        <ContentDiv>{children}</ContentDiv>
      </BodyDiv>
    </div>
  );
}
export default AuthBase;


const FixedHeader = styled.div`
  position: fixed;
  width: 100%;
`;

const BodyDiv = styled.div`
  padding-top: 80px;

  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  background-color: ${palette.backgroundGray};
`;

const ContentDiv = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 1200px;
`;
