import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import palette from '@/ui/css/palette';
import { BInput } from '@/components/atoms/Input';

function PhoneNumberInputBox(props) {
  const {
    value, error, errorMessage, onEmitChange, className,
  } = props;

  return (
    <div className={className}>
      {error && <StyledSpan>{errorMessage}</StyledSpan>}
      <Wrapper>
        <StyledBInput
          value={value.first}
          error={error ? 1 : 0}
          onChange={(e) => onEmitChange(e, 'first')}
        />
        <Hyphen>-</Hyphen>
        <StyledBInput
          value={value.second}
          error={error ? 1 : 0}
          onChange={(e) => onEmitChange(e, 'second')}
        />
        <Hyphen>-</Hyphen>
        <StyledBInput
          value={value.third}
          error={error ? 1 : 0}
          onChange={(e) => onEmitChange(e, 'third')}
        />
      </Wrapper>
    </div>
  );
}

export default PhoneNumberInputBox;

PhoneNumberInputBox.propTypes = {
  value: PropTypes.shape({
    first: PropTypes.string,
    second: PropTypes.string,
    third: PropTypes.string,
  }).isRequired,
};

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 360px;
`;

const StyledBInput = styled(BInput)`
  display: inline-block;
  width: 100px;
  height: 40px;
  border-color: ${(props) => (props.error ? palette.red : palette.whiteDarkDark)};
`;

const StyledSpan = styled.span`
  color: ${palette.red};
  margin-right: 20px;
`;

const Hyphen = styled.p`
  margin: 0;
`;
