import React from 'react';
import styled from 'styled-components';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import { OrbitronSpan } from '@/components/atoms/Label';
import palette from '@/ui/css/palette';

function ServiceUse(props) {
  const { scopeList } = props;

  const canAccessAnalytics = () => scopeList.includes('i.access');

  return (
    <div>
      <StyledDiv>
        {renderIcon(canAccessAnalytics())}
        <StyledOrbitronSpan>loplat Analytics</StyledOrbitronSpan>
      </StyledDiv>
    </div>
  );
}
export default ServiceUse;

function renderIcon(boolean) {
  return boolean === true
    ? <StyledIoMdCheckmark />
    : <StyledIoMdClose />;
}

const StyledDiv = styled.div`
  display: inline-block;
  margin-left: 30px;
`;

const StyledIoMdCheckmark = styled(IoMdCheckmark)`
  margin: 10px;
  color: ${palette.primaryLight};
`;

const StyledIoMdClose = styled(IoMdClose)`
  margin: 10px;
  color: ${palette.darkLight};
`;

const StyledOrbitronSpan = styled(OrbitronSpan)`
  color: ${palette.darkLight};
`;
