import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import palette from '@/ui/css/palette';
import routes from '@/configs/routes';
import PasswordAPI from '@/api/password';
import { passwordCompareError, passwordFormatError } from '@/util/validation';
import FlexContainer, { ShadowCard } from '@/components/atoms/Div';
import PasswordResetForm from '@/components/organisms/PasswordResetForm';


function PasswordReset(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const token = props.match.params.token;

  useEffect( ()=> {
    (async () => {
      try {
        await PasswordAPI.testToken(token);
        setTokenError(false);
      } catch {
        setTokenError(true);
      }
    })();
  }, [token]);

  const resetPassword = async (newPassword, newPasswordConfirm) => {
    setErrorMessage('');

    const passwordErrorCheck = passwordFormatError(newPassword);
    if (passwordErrorCheck !== '') {
      setErrorMessage(passwordErrorCheck);
      return ;
    }

    const passwordCompareCheck = passwordCompareError(newPassword, newPasswordConfirm);
    if (passwordCompareCheck !== '') {
      setErrorMessage(passwordCompareCheck);
      return ;
    }

    setIsLoading(true);
    try {
      const response = await PasswordAPI.resetPassword(newPassword, token);
      if (response.status === 200) {
        toast(<div>비밀번호 설정이 완료 되었습니다.<br /> 다시 로그인 해주세요.</div>);
        props.history.push(routes.LOG_IN);
      }
    } catch {
      setErrorMessage('비밀번호 재설정에 실패하였습니다.');
    }
    setIsLoading(false);
  };

  return (
    <StyledFlexContainer>
      { tokenError ?
        <StyledShadowCard>
          <div>
            <StyledParagraph>현재 페이지가 만료되어 찾을 수 없습니다.</StyledParagraph>
            <StyledParagraph>비밀번호 찾기를 다시 설정해 주세요.</StyledParagraph>
          </div>
        </StyledShadowCard> :
        <PasswordResetForm errorMessage={errorMessage} onSubmit={resetPassword} isLoading={isLoading}/>
      }
    </StyledFlexContainer>
  );
}

export default PasswordReset;


const StyledFlexContainer = styled(FlexContainer)`
  height: 70vh;
`;

const StyledShadowCard = styled(ShadowCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 560px;
  height: 160px;
`;

const StyledParagraph = styled.p`
  text-align: center;
  margin-bottom: 5px;
  color: ${palette.red};
  font-size: 20px;
`;
