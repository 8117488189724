export default {
  black: '#222222',
  dark: '#314050',
  darkLight: '#59697a',
  grayLight: '#bebebe',
  grayDark: '#a2a8b0',

  pureWhite: '#ffffff',
  white: '#f5f5f5',
  whiteDark: '#f8f8f8',
  whiteDarkDark: '#e1e2ea',
  whiteDarkLight: '#e8eaef',
  backgroundGray: '#fbfbfb',
  borderGray: '#e8eaef',
  buttonGray: '#f2f2f2',

  primaryLight: '#41a7ff',
  buttonText: '#004eff',

  activeStatus: '#1e9b6d',
  inactiveStatus: '#8499ae',

  red: '#ea574d',

  mint50: '#64edd3',
  white20: '#e1e2ea',
};
