import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import palette from '@/ui/css/palette';
import Navigation from '@/components/organisms/Header/Navigation';
import DropDowns from '@/components/organisms/Header/DropDowns';
import LinkButton from '@/components/atoms/LinkButton';
import routes from '@/configs/routes';

function Header() {
  const location = useLocation();
  const mainPage = location.pathname.startsWith('/settings');
  const passwordPage = location.pathname.startsWith('/pw');

  return (
    <FlexContainer className="Header">
      <FlexItem><StyledImg src={require(`@/ui/image/logo/loplat-manager.svg`)} alt=""/></FlexItem>
      {mainPage && <FlexItem><Navigation/></FlexItem>}
      {mainPage && <FlexItem><DropDowns/></FlexItem>}
      {passwordPage && <FlexItem><StyledLinkButton title={'로그인'} path={routes.LOG_IN}/></FlexItem> }
    </FlexContainer>
  );
}

export default Header;


const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border: 1px solid ${palette.whiteDarkDark};
  background-color: ${palette.pureWhite};
`;

const FlexItem = styled.div`
  width: 100%;
`;

const StyledImg = styled.img`
  height: 28px;
  margin-left: 40px;
`;

const StyledLinkButton = styled(LinkButton)`
  float: right;
  margin-right: 40px;
`;
