import React from 'react';
import { useHistory } from 'react-router-dom';

import palette from '@/ui/css/palette';
import styled from 'styled-components';
import Button from '@/components/atoms/Button';


function LinkButton(props) {
  const { className, path, title, passState } = props;
  const history = useHistory();

  return (
    <StyledButton
      className={className}
      onClick={() => history.push(path, {passState})}
    >
      {title}
    </StyledButton>
  );
}
export default LinkButton;


const StyledButton = styled(Button)`
  color: ${palette.dark};
  
  &:hover {
    color: ${palette.primaryLight};
  }
`;


