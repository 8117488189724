import React from 'react';
import styled from 'styled-components';
import Table from 'reactstrap/es/Table';
import { upperFirst } from 'lodash';

import palette from '@/ui/css/palette';
import UserAPI from '@/api/users';
import routes from '@/configs/routes';
import LinkButton from '@/components/atoms/LinkButton';

class UserTable extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.page = 1;

    this.state = {
      userList: [],
      totalCount: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUserList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserList() {
    (async () => {
      let response;
      try {
        response = await UserAPI.userList(this.page);
      } catch (e) {
        console.log('error on GET api');
        return;
      }

      const { data } = response;
      if (this._isMounted) {
        this.setState({
          userList: data.items,
          totalCount: data.size,
        });
      }
    })();
  }

  render() {
    const { userList, totalCount } = this.state;
    const tableHeaderNames = ['이메일', '이름', '권한', '등록일시'];

    return (
      <div className="UserTable">
        <StyledParagraph className="UserTableTitle">
          사용자 목록
          {`| ${totalCount}명`}
        </StyledParagraph>

        <Table borderless>
          <StyledThead>
            <tr>
              {tableHeaderNames.map((name) => <th key={name}>{name}</th>)}
            </tr>
          </StyledThead>
          <StyledTbody>
            {
              (userList).map((user) => (
                <tr key={user.user_id}>
                  <td style={{ width: '50%' }}>
                    <StyledButton
                      path={routes.USER_EDIT}
                      title={user.user_id}
                      passState={user}
                    />
                  </td>
                  <td style={{ width: '15%' }}>{user.username}</td>
                  <td style={{ width: '15%' }}>{upperFirst(user.role)}</td>
                  <td style={{ width: '20%' }}>{user.created_at}</td>
                </tr>
              ))
            }
          </StyledTbody>
        </Table>
      </div>
    );
  }
}
export default UserTable;

const StyledParagraph = styled.p`
  margin-top: 60px;
  font-size: 16px;
  color: ${palette.darkLight};
`;

const StyledThead = styled.thead`
  background-color: ${palette.white};
  
  th {
    vertical-align: middle !important;
    padding-left: 30px;
    height: 60px;
    font-size: 16px;
    font-weight: normal;
    color: ${palette.darkLight};
  }
`;

const StyledTbody = styled.tbody`
  tr {
    &:hover {
      background-color: ${palette.pureWhite};
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
  }
  
  td {
    vertical-align: middle;
    padding-left: 30px;
    font-size: 14px;
    color: ${palette.darkLight};
  }
`;

const StyledButton = styled(LinkButton)`
  color: ${palette.buttonText};
`;
