import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { emailFormatError, passwordRequiredError } from '@/util/validation';
import { UserContext } from '@/util/context';
import Token from '@/util/token';
import MyInfoAPI from '@/api/myInfo';
import routes from '@/configs/routes';

import LoginForm from '@/components/organisms/LoginForm';
import AuthBase from '@/templates/AuthBase';
import FlexContainer from '@/components/atoms/Div';

class Login extends React.Component {
  constructor(props) {
    super(props);

    MyInfoAPI.wakeUp().then();

    this.state = {
      errorMessage: '',
      isLoading: false,
    };
  }

  async login(email, password) {
    this.setState({ errorMessage: '' });

    // email validation check
    const emailErrorCheck = emailFormatError(email);
    if (emailErrorCheck !== '') {
      this.setState({ errorMessage: emailErrorCheck });
      return;
    }

    // password validation check
    const passwordErrorCheck = passwordRequiredError(password);
    if (passwordErrorCheck !== '') {
      this.setState({ errorMessage: passwordErrorCheck });
      return;
    }

    this.setState({ isLoading: true });
    // login check
    try {
      const response = await MyInfoAPI.login(email, password);
      if (response.status === 200) {
        // Login Success
        const token = response.data;
        Token.setToken(token);

        const value = this.context;
        value.setToken(token);
        toast('로그인 되었습니다.');
        return;
      }
    } catch {
      this.setState({ errorMessage: '잘못된  로그인입니다. 다시 한번 확인해 주세요.' });
    }
    this.setState({ isLoading: false });
  }

  render() {
    const { errorMessage, isLoading } = this.state;
    const value = this.context;

    return (
      <AuthBase>
        {value.accessToken && <Redirect to={routes.MY_PROFILE} /> }
        <StyledFlexContainer>
          <LoginForm
            onSubmit={(email, password) => this.login(email, password)}
            isLoading={isLoading}
            errorMessage={errorMessage}
          />
        </StyledFlexContainer>
      </AuthBase>
    );
  }
}
export default Login;
Login.contextType = UserContext;

const StyledFlexContainer = styled(FlexContainer)`
  height: 70vh;
`;
