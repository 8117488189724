import React from 'react';
import styled from 'styled-components';
import palette from '@/ui/css/palette';
import { BInput } from '@/components/atoms/Input';

function PasswordChangeInput(props) {
  const { onEmitChange, values, errors, className } = props;

  return (
    <FlexContainer className={className}>
      <FlexItem>
        <DarkSpan>{"비밀번호 변경"}</DarkSpan>
      </FlexItem>
      <FlexInputItem>
        <StyledDiv>
          {errors.currentError && <RedSpan>{errors.currentError}</RedSpan>}
          <StyledBInput
            value={values.current} error={errors.currentError ? 1 : 0} type="password"
            placeholder={"현재 비밀번호 입력"} onChange={(e) => onEmitChange(e, 'current')}/>
        </StyledDiv>
        <StyledDiv>
          {errors.formatError && <RedSpan>{errors.formatError}</RedSpan>}
          <StyledBInput
            value={values.new} error={errors.formatError ? 1 : 0} type="password"
            placeholder={"새로운 비밀번호 입력"} onChange={(e) => onEmitChange(e, 'new')}/>
        </StyledDiv>
        <StyledDiv>
          {errors.compareError && <RedSpan>{errors.compareError}</RedSpan>}
          <StyledBInput
            value={values.newConfirm} error={errors.compareError ? 1 : 0} type="password"
            placeholder={"새로운 비밀번호 확인"} onChange={(e) => onEmitChange(e, 'newConfirm')}/>
        </StyledDiv>
      </FlexInputItem>
    </FlexContainer>
  );
}

export default PasswordChangeInput;


const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${palette.pureWhite};
  height: 180px;
  border: 1px solid ${palette.borderGray};
`;

const FlexItem = styled.div`
  padding: 30px;
`;

const FlexInputItem = styled.div`
  padding: 20px 30px 30px;
`;

const RedSpan = styled.span`
  color: ${palette.red};
  margin-right: 20px;
`;

const DarkSpan = styled.span`
  color: ${palette.black};
`;

const StyledBInput = styled(BInput)`
  display: inline-block;
  width: 360px;
  height: 40px;
  margin-bottom: 10px;
  border-color: ${props => props.error ? palette.red : palette.whiteDarkDark};
`;

const StyledDiv = styled.div`
  text-align: end;
`;
