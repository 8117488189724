import React from 'react';
import styled from 'styled-components';
import useInput from '@/util/hook';
import { enterKeyPressed } from '@/util/event';

import { FormTitle } from '@/components/atoms/Title';
import { FormInput } from '@/components/atoms/Input';
import { FormErrorLabel } from '@/components/atoms/Label';
import { BButton } from '@/components/atoms/Button';


function PasswordFindForm(props) {
  const { errorMessage, onSubmit, isLoading } = props;
  const [email, setEmail] = useInput('');

  return (
    <StyledFindForm>
      <FormTitle>비밀번호 찾기</FormTitle>
      <FormInput value={email} onChange={setEmail} placeholder="이메일을 입력해 주세요." type="email"
                 error={errorMessage} onKeyPress={(e) => enterKeyPressed(e) && onSubmit(email)}
      />
      <FormErrorLabel>{errorMessage}</FormErrorLabel>
      <BButton onClick={() => onSubmit(email)} color="primary" size="lg" disabled={isLoading}>비밀번호 리셋 링크 전송</BButton>
    </StyledFindForm>
  );
}
export default PasswordFindForm;


const StyledFindForm = styled.div`
  display: flex;
  flex-flow: nowrap column;
  width: 350px;
`;
