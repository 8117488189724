import React from 'react';

function NotFound() {
  return (
    <div>
      잘못된 URL 입니다
    </div>
  );
}

export default NotFound;
