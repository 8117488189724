import Cookies from 'js-cookie';
import * as CONFIG from '@/config';

export const setCookie = (key, value) => {
  Cookies.set(key, value, { path: '/', domain: CONFIG.COOKIE_DOMAIN });
}

export const getCookie = key => {
  return Cookies.get(key);
}

export const removeCookie = key => {
  Cookies.remove(key, { path: '/', domain: CONFIG.COOKIE_DOMAIN });
}