import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useInput from '@/util/hook';
import { enterKeyPressed } from '@/util/event';
import palette from '@/ui/css/palette';
import routes from '@/configs/routes';
import { getCookie, removeCookie, setCookie } from '@/util/cookie';

import { FormTitle } from '@/components/atoms/Title';
import { FormInput } from '@/components/atoms/Input';
import { FormErrorLabel } from '@/components/atoms/Label';
import { BButton } from '@/components/atoms/Button';
import LinkButton from '@/components/atoms/LinkButton';
import EmailMemorizeCheckbox from '@/components/molecules/EmailMemorizeCheckbox';

function LoginForm(props) {
  const emailCookieKey = 'memorized_email';
  const { errorMessage, onSubmit, isLoading } = props;

  const [emailChecked, setEmailChecked] = useState(false);
  const [email, setEmail] = useInput('');
  const [password, setPassword] = useInput('');

  useEffect(() => {
    const memorizedEmail = getCookie(emailCookieKey);
    if (memorizedEmail) {
      setEmail({ target: { value: memorizedEmail } });
      setEmailChecked(true);
    }
  }, []);

  const handleClickLogin = () => {
    if (emailChecked) {
      setCookie(emailCookieKey, email);
    } else {
      removeCookie(emailCookieKey);
    }
    onSubmit(email, password);
  };

  return (
    <StyledLoginForm>
      <FormTitle>로그인</FormTitle>
      <FormInput value={email} onChange={setEmail} placeholder="이메일을 입력해 주세요." type="email" error={errorMessage} />
      <FormInput
        value={password}
        onChange={setPassword}
        placeholder="비밀번호를 입력해 주세요."
        type="password"
        autoComplete="current-password"
        error={errorMessage}
        onKeyPress={(e) => enterKeyPressed(e) && handleClickLogin()}
      />
      <FormErrorLabel>{errorMessage}</FormErrorLabel>
      <EmailMemorizeCheckbox checked={emailChecked} handleChange={(bool) => setEmailChecked(bool)} />
      <BButton onClick={handleClickLogin} color="primary" size="lg" disabled={isLoading}>로그인 하기</BButton>
      {/* 비밀번호 찾기 기능은 추후 개발 예정 */}
      {/* <StyledLinkButton title="비밀번호 찾기" path={routes.PASSWORD_FIND} /> */}
    </StyledLoginForm>
  );
}

export default LoginForm;

const StyledLoginForm = styled.div`
  display: flex;
  flex-flow: nowrap column;
  width: 350px;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-top: 10px;
  margin-left: auto;
  padding: 0;
  
  color: ${palette.darkLight};
  font-size: 14px;
`;
