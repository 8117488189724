/** 페이지가 변할 때마다, 토큰 만료 시간을 초기화 한다. **/
/** 만료 시간이 끝나면 자동 로그아웃 시킨다. **/
import Token from '@/util/token';


class ExpireCheck {
  startNewSetInterval() {
    // 토큰 만료 시간 초기화
    Token.initializeToken();
    // 타이머 시작
    this.remainTime = 60 * 60;
    this.timer = setInterval(() => {
      this.remainTime -= 10;

      if (this.remainTime === 60 * 5) {
        // TODO: 토큰 시간 연장 팝업
      } else if ( this.remainTime < 0) {
        // 토큰 시간 만료 로그아웃
        Token.removeCookieToken();
        window.location.replace('/login');
      }
    }, 1000 * 10);
  }

  clearSetInterval() {
    // 타이머 삭제
    clearInterval(this.timer);
  }
}
export default new ExpireCheck();