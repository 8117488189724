import FirebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import * as config from '@/config';

export const firebase = FirebaseApp.initializeApp({
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  databaseURL: config.FIREBASE_DATABASE_URL,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASUREMENT_ID,
});

export const auth = firebase.auth();
export const firestore = firebase.firestore();
