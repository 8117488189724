import axios from 'axios';
import Token from '@/util/token';
import MyInfoAPI from '@/api/myInfo';
import * as CONFIG from '@/config';

const ManagerAPI = axios.create({
  baseURL: CONFIG.API_AUTH_URL,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
    'Content-Type': 'application/json',
  },
});
export default ManagerAPI;

/** 토큰이 필요한 API 의 header 에 access_token 추가 * */
ManagerAPI.interceptors.request.use((request) => {
  const noTokenAPIList = ['token', 'password', 'warmup'];
  if (noTokenAPIList.some((keyword) => request.url.includes(keyword))) {
    return request;
  }

  const accessToken = Token.getAccessToken();
  request.headers.Authorization = `Bearer ${accessToken}`;

  return request;
}, (error) => Promise.reject(error));

/** 토큰 만료 시 refresh * */
ManagerAPI.interceptors.response.use(null, (error) => {
  console.log(error.response);
  if (error.response && error.response.status === 401 && error.response.data.error === 'TokenExpired') {
    const refreshToken = Token.getRefreshToken();
    if (refreshToken) {
      MyInfoAPI.refresh(refreshToken)
        .then((response) => {
          const token = response.data;
          Token.setToken(token);
          // TODO: context state 변경이 안돼서 일단 reload
          window.location.reload();
        })
        .catch(() => {
          Token.removeCookieToken();
          window.location.replace('/login');
        });
    }
  }

  return Promise.reject(error);
});
