import React from 'react';
import { PageTitle } from '@/components/atoms/Title';
import UserTable from '@/components/organisms/Table/UserTable';


class UserManager extends React.Component {
  render() {
    return (
      <div className="UserManager">
        <PageTitle>사용자 관리</PageTitle>

        <UserTable/>
      </div>
    );
  }
}
export default UserManager;
