/** Cookie 에 있는 token, refresh_token 을 관리 **/
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import * as CONFIG from '@/config';
import { removeCookie } from '@/util/cookie';


class Token {
  initializeToken() {
    /**
     * 1. cookie 에 token 이 있으면 자동 로그인
     * 2. setCookieToken 을 통해 expire time 초기화
     */
    const cookieAccessToken = Cookies.get('access_token');
    const cookieRefreshToken = Cookies.get('refresh_token');

    if (cookieAccessToken && cookieRefreshToken) {
      this.setCookieToken(cookieAccessToken, cookieRefreshToken);
    }
  }

  getAccessToken() {
    return Cookies.get('access_token');
  }

  getRefreshToken() {
    return Cookies.get('refresh_token');
  }

  getDecodedAccessToken(accessToken)  {
    return accessToken ? jwtDecode(accessToken) : '';
  }

  setCookieToken(accessToken, refreshToken) {
    accessToken && Cookies.set('access_token', accessToken, { expires: 1/24, path: '/', domain: CONFIG.COOKIE_DOMAIN });
    refreshToken && Cookies.set('refresh_token', refreshToken, { expires: 1/24, path: '/', domain: CONFIG.COOKIE_DOMAIN });
  }

  setToken(token) {
    if (token.access_token) {
      this.setCookieToken(token.access_token, token.refresh_token || null);
    } else {
      this.removeCookieToken();
    }
  }

  removeCookieToken() {
    removeCookie('access_token');
    removeCookie('refresh_token');
  }
}
export default new Token();
