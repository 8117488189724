export default {
  LOG_IN: '/login',

  PASSWORD: '/pw',
  PASSWORD_FIND: '/pw/find',
  PASSWORD_RESET: '/pw/reset/:token',

  MAIN: '/',
  MY_PROFILE: '/settings/mypage',
  USER_MANAGER: '/settings/manage_users',
  USER_EDIT: '/settings/manage_users/edit',
};
