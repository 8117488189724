import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import routes from '@/configs/routes';

import Login from '@/pages/Login';
import MainRouter from '@/templates/Base/MainRouter';
import AuthRouter from '@/templates/AuthBase/AuthRouter';


function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path={routes.LOG_IN} component={Login} />
        <Route path={routes.PASSWORD} component={AuthRouter} />
        <Route path={routes.MAIN} component={MainRouter} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
