import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import routes from '@/configs/routes';
import Token from '@/util/token';
import { UserContext } from '@/util/context';

import Base from '@/templates/Base';
import UserManager from '@/pages/UserManager';
import UserEdit from '@/pages/UserEdit';
import MyProfile from '@/pages/MyProfile';
import NotFound from '@/pages/NotFound';


function MainRouter() {
  const value = useContext(UserContext);

  const isManager = () => {
    const decodedAccessToken = Token.getDecodedAccessToken(value.accessToken);
    const userRole = decodedAccessToken ? decodedAccessToken.user_claims.role : '';
    return userRole === 'manager';
  };

  return (
    <Base>
      { !value.accessToken && <Redirect to={routes.LOG_IN} /> }
      <Switch className="MainRouter">
        <Route exact path={routes.MAIN}>{value.accessToken && <Redirect to={routes.MY_PROFILE}/>}</Route>
        <Route exact path={routes.MY_PROFILE} component={MyProfile} />
        { isManager() && <Route exact path={routes.USER_MANAGER} component={UserManager} /> }
        { isManager() && <Route exact path={routes.USER_EDIT} component={UserEdit} /> }
        <Route component={NotFound} />
      </Switch>
    </Base>
  );

}
export default MainRouter;
