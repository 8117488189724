import React from 'react';
import styled from 'styled-components';
import { Input as ReactstrapInput } from 'reactstrap';
import { withStyles, Switch as MSwitch } from '@material-ui/core';
import palette from '@/ui/css/palette';

const Input = styled(ReactstrapInput)`
  border-radius: 10px;
  border-width: 1px;
  
  font-size: 18px;
  height: 56px;
  
  &:focus {
    border-color: ${palette.primaryLight};
    box-shadow: none;
  }
`;
export default Input;

export const FormInput = styled(Input)`
  margin: 10px 0;
  
  box-sizing: border-box;
  font-size: 18px;
  height: 56px;
  
  border-color: ${(props) => (props.error ? palette.red : '')};
  
  &:after {
    content: '';
  }
`;

export const BInput = styled(ReactstrapInput)`
  color: ${palette.black};

  &:focus {
    box-shadow: none;
  }
  
  &::placeholder {
    color: ${palette.grayLight};
  }
`;

export const Switch = withStyles((theme) => ({
  root: {
    width: 56,
    height: 32,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    height: '100%',
    '&$checked': {
      transform: 'translateX(30px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: palette.mint50,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 56 / 2,
    border: `1px solid ${palette.white20}`,
    backgroundColor: palette.white,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <MSwitch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));
