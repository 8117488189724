import React from 'react';
import styled from 'styled-components';
import palette from '@/ui/css/palette';
import { BInput } from '@/components/atoms/Input';


function InputBox(props) {
  const { placeholder, value, error, errorMessage, onEmitChange, className } = props;

  return (
    <div className={className}>
      {error && <StyledSpan>{errorMessage}</StyledSpan>}
      <StyledBInput
        placeholder={placeholder}
        value={value}
        error={error ? 1: 0}
        onChange={(e) => onEmitChange(e)}
      />
    </div>
  );
}

export default InputBox;


const StyledBInput = styled(BInput)`
  display: inline-block;
  width: 360px;
  height: 40px;
  border-color: ${props => props.error ? palette.red : palette.whiteDarkDark};
`;

const StyledSpan = styled.span`
  color: ${palette.red};
  margin-right: 20px;
`;
