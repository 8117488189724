/**
 * 주간 시작점 설정 기능이 i manager 에서 i 로 이관됨.
 */
import { firestore } from '@/firebase';

const collectionName = 'analytics_users';

export async function getFirestoreUserInfo(email) {
  let firestoreUserInfo = null;
  await firestore.collection(collectionName)
    .doc(email)
    .get()
    .then((doc) => {
      if (doc.exists) {
        firestoreUserInfo = doc.data();
      } else {
        const defaultInfo = getFirestoreUserDefaultValue(email);
        firestore.collection(collectionName).doc(email).set(defaultInfo);
        firestoreUserInfo = defaultInfo;
      }
    });
  return firestoreUserInfo;
}

export async function setFirestorePhoneNumber(email, phoneNumber) {
  await firestore.collection(collectionName)
    .doc(email)
    .update({ email, phone: phoneNumber ? [phoneNumber] : [] });
}

function getFirestoreUserDefaultValue(email) {
  return {
    email,
    interest: [],
    phone: [],
    startOfWeek: 'SUN',
    endOfWeekday: 'FRI',
    notification: {
      sms: {
        allow: false,
      },
      smsEvent: {
        allow: false,
        condition: 25,
        time: '0 5 * * *',
      },
      smsRegular: {
        allow: false,
        time: '0 5 * * *',
      },
    },
  };
}
