import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '@/configs/routes';

import NotFound from '@/pages/NotFound';
import PasswordFind from '@/pages/PasswordFind';
import PasswordReset from '@/pages/PasswordReset';
import AuthBase from '@/templates/AuthBase/index';


function AuthRouter() {
  return (
    <AuthBase>
      <Switch className="AuthBase">
        <Route exact path={routes.PASSWORD_FIND} component={PasswordFind} />
        <Route exact path={routes.PASSWORD_RESET} component={PasswordReset} />
        <Route component={NotFound} />
      </Switch>
    </AuthBase>
  );
}
export default AuthRouter;
